import { FETCH_SETTINGS, UPDATE_SETTINGS } from '../actionTypes';

const initialState = {
  loading: true,
  capacity: null,
  currency: null,
  workdays: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTINGS: {
      const { capacity, currency, workdays } = action.payload.settings;

      return {
        ...state,
        loading: false,
        capacity,
        currency,
        workdays
      };
    }

    case UPDATE_SETTINGS: {
      const { settings } = action.payload;

      return {
        ...state,
        ...settings
      };
    }

    default:
      return state;
  }
}
