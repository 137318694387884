import { LOG_IN, LOG_OUT, COMPLETE_ONBOARDING } from '../actionTypes';
import { firebase, auth, db } from '../../lib/firebase';
import { fetchSettings } from './settings';

export const authenticate = service => async dispatch => {
  let provider;

  // eslint-disable-next-line default-case
  switch (service) {
    case 'Google':
      provider = new firebase.auth.GoogleAuthProvider();
      break;

    case 'Twitter':
      provider = '';
      break;

    case 'Github':
      provider = '';
      break;
  }

  auth.signInWithPopup(provider);
};

export const logIn = authObj => async dispatch => {
  const { uid } = authObj;
  const user = await db.doc(`users/${uid}`).get();

  if (!user) {
    dispatch(signUp(authObj));
  } else {
    /** Asyncronously updated the lastLoginAt property */
    db.doc(`users/${uid}`).update({ lastLoginAt: new Date() });

    /** Grab the settings for the user */
    await dispatch(fetchSettings(uid));

    dispatch({
      type: LOG_IN,
      payload: { user: { uid, ...user.data() } }
    });
  }
};

export const signUp = authObj => async dispatch => {
  const { uid, email, displayName, photoURL } = authObj;
  const now = new Date();

  /** Create user record */
  const user = db.doc(`/users/${uid}`).set({
    createdAt: now,
    updatedAt: now,
    email,
    displayName,
    photoUrl: photoURL,
    onboarded: false
  });

  const settings = db.doc(`/settings/${uid}`).set({
    createdAt: now,
    updatedAt: now,
    capacity: 40,
    currency: 'USD',
    workdays: {
      mon: true,
      tues: true,
      wed: true,
      thurs: true,
      fri: true,
      sat: false,
      sun: false
    }
  });

  try {
    await Promise.all([user, settings]);
    dispatch(logIn(authObj));
  } catch (e) {
    // dispatch(authError(e));
  }
};

export const completeOnboarding = uid => async dispatch => {
  await db.doc(`/users/${uid}`).update({ onboarded: true });
  dispatch({ type: COMPLETE_ONBOARDING });
};

export const logOut = () => async dispatch => {
  auth.signOut();
  dispatch({ type: LOG_OUT });
};
