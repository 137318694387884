import React from 'react';
import { Link } from 'react-router-dom';

import PublicNavigation from '../../components/PublicNavigation';

import styles from './Home.module.scss';
import Button from '../../components/Button/Button';

import Screenshot from '../../images/screenshot.png';

export default function Home() {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.publicNavigation}>
          <PublicNavigation />
        </div>

        <div className={styles.welcome}>
          <div className={styles.screenshot}>
            <img src={Screenshot} alt="Screenshot of the Willo interface" />
          </div>

          <div className={styles.text}>
            <h1 className={styles.title}>Clarity for your freelance work.</h1>
            <h3 className={styles.subtitle}>
              Know when you’re available for work, track cashflow, and stay on
              top of the conversations you have with clients.
            </h3>

            <div className={styles.signUp}>
              <Link to="/sign-up">
                <Button>Sign up</Button>
              </Link>
            </div>
            <small>Free for your first 2 projects.</small>
          </div>
        </div>
      </header>

      <div className={styles.features}></div>
    </>
  );
}
