import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { completeOnboarding } from '../../redux/actions/auth';
import { updateSettings } from '../../redux/actions/settings';

import SettingsForm from '../../components/SettingsForm';
import SignUpFrame from '../../components/SignUpFrame';
import Button from '../../components/Button';

import styles from './SignUp2.module.scss';

export default function SignUp2() {
  const dispatch = useDispatch();

  const user = useSelector(s => s.auth.user);
  const { loading, ...settings } = useSelector(s => s.settings);

  const [saving, setSaving] = useState(false);
  const [updatedSettings, setUpdatedSettings] = useState(settings);

  async function finish() {
    const { uid } = user;

    setSaving(true);
    await dispatch(updateSettings(uid, updatedSettings));
    dispatch(completeOnboarding(user.uid));
  }

  function footer() {
    return (
      <>
        <p className={styles.fillLater}>Want to fill this out later?</p>
        <Button onClick={finish} theme="secondary">
          Skip
        </Button>
      </>
    );
  }

  return (
    <SignUpFrame
      title={`Welcome aboard, ${user.displayName}!`}
      subtitle="A couple more questions before we’re ready to get started."
      footer={footer()}
    >
      <SettingsForm
        settings={updatedSettings}
        onChange={s => setUpdatedSettings(s)}
      />

      <div className={styles.footer}>
        <Button loading={saving} onClick={finish}>
          Let's go!
        </Button>
      </div>
    </SignUpFrame>
  );
}
