import React from 'react';
import classNames from 'classnames';

import Loading from '../Loading';

import styles from './Button.module.scss';

export default function Button({
  loading,
  theme = 'default',
  children,
  fill,
  ...rest
}) {
  const classes = classNames({
    [styles.button]: true,
    [styles[theme]]: true,
    [styles.fill]: !!fill,
    [styles.loading]: !!loading
  });

  return (
    <button className={classes} {...rest}>
      <div className={styles.text}>{children}</div>

      {loading && (
        <div className={styles.loader}>
          <Loading theme="blue" size={20} />
        </div>
      )}
    </button>
  );
}
