import React from 'react';
import { Link } from 'react-router-dom';

import AuthButton from '../../components/AuthButton';
import SignUpFrame from '../../components/SignUpFrame';

// import styles from './SignUp1.module.scss';

export default function SignUp1() {
  function footer() {
    return (
      <>
        <p>Not ready to sign up yet?</p>
        <p>
          <Link to="/">Back to home</Link>
        </p>
      </>
    );
  }
  return (
    <SignUpFrame
      title="Let's make this easy."
      subtitle="Sign-up with an existing account to get started."
      footer={footer()}
    >
      <AuthButton provider="Google" signUp />
      <AuthButton provider="Twitter" signUp />
      <AuthButton provider="Github" signUp />
    </SignUpFrame>
  );
}
