import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logOut } from '../../redux/actions/auth';

import Button from '../../components/Button';

// import styles from './Willo.module.scss';

export default function Willo() {
  const dispatch = useDispatch();
  const onboarded = useSelector(s => s.auth.user.onboarded);

  /** If not onboarded yet, redirect to `/sign-up/2` */
  // if (!onboarded) dispatch(replace('/sign-up/2'));
  if (!onboarded) return <Redirect to="/sign-up/2" />;
  return <Button onClick={() => dispatch(logOut())}>Log out</Button>;
}
