import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import LoadingFrame from '../LoadingFrame';

// import styles from './ProtectedRoute.module.scss';

export default function ProtectedRoute({ ...route }) {
  const loading = useSelector(store => store.auth.loading);
  const authed = useSelector(store => store.auth.authenticated);

  if (loading) return <LoadingFrame />;
  return authed ? <Route {...route} /> : <Redirect to="/" />;
}
