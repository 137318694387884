import React from 'react';
import styles from './Select.module.scss';

export default function Select({ children, value, onChange, ...rest }) {
  return (
    <select
      className={styles.select}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {children}
    </select>
  );
}
