import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';

import LoadingFrame from '../../components/LoadingFrame';

import SignUp1 from '../SignUp1';
import SignUp2 from '../SignUp2';

// import styles from './SignUp.module.scss';

export default function SignUp() {
  const dispatch = useDispatch();

  const authenticated = useSelector(s => s.auth.authenticated);
  const onboarded = useSelector(s => s.auth.user && s.auth.user.onboarded);
  const loading = useSelector(s => s.auth.loading);

  useEffect(() => {
    if (authenticated) {
      onboarded ? dispatch(replace('/a')) : dispatch(push('/sign-up/2'));
    }
  }, [dispatch, authenticated, onboarded]);

  function renderLoading() {
    return <LoadingFrame />;
  }

  function renderSignUpRoutes() {
    return (
      <Switch>
        <Redirect from="/sign-up" to="/sign-up/1" exact />

        <Route path="/sign-up/1" component={SignUp1} />
        <Route path="/sign-up/2" component={SignUp2} />
      </Switch>
    );
  }

  return loading ? renderLoading() : renderSignUpRoutes();
}
