import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';

import { history } from '../../redux/store';
import { auth } from '../../lib/firebase';
import { logIn, logOut } from '../../redux/actions/auth';

import Home from '../Home';
import SignUp from '../SignUp';
import Willo from '../Willo';

import ProtectedRoute from '../../components/ProtectedRoute';

import './App.module.scss';

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkAuthState() {
      auth.onAuthStateChanged(user => {
        user ? dispatch(logIn(user)) : dispatch(logOut());
      });
    }

    checkAuthState();
  }, [dispatch]);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/sign-up" component={SignUp} />

        <ProtectedRoute path="/a" component={Willo} />
      </Switch>
    </ConnectedRouter>
  );
}
