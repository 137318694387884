import React from 'react';
import { Link } from 'react-router-dom';

import Wordmark from '../../components/Wordmark';

import styles from './PublicNavigation.module.scss';
import Button from '../Button/Button';

export default function PublicNavigation() {
  return (
    <div className={styles.wrap}>
      <Link to="/">
        <Wordmark />
      </Link>

      <nav className={styles.nav}>
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a href="#support">Support</a>
        <Button>Log in</Button>
      </nav>
    </div>
  );
}
