import React from 'react';
import styles from './SettingsRow.module.scss';

export default function SettingsRow({ title, subtitle, children }) {
  return (
    <div className={styles.wrap}>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
}
