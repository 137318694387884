import React, { useReducer, useEffect } from 'react';

import { currencies } from '../../helpers/currencies';
import { days } from '../../helpers/days';

import SettingsRow from '../SettingsRow';
import DayOfWeek from '../DayOfWeek';
import HoursPerWeek from '../HoursPerWeek';
import Select from '../Select';

// import styles from './SettingsForm.module.scss';

const UPDATE_DAY_OF_WEEK = 'UPDATE_DAY_OF_WEEK';
const UPDATE_CAPACITY = 'UPDATE_CAPACITY';
const UPDATE_CURRENCY = 'UPDATE_CURRENCY';

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_DAY_OF_WEEK:
      return {
        ...state,
        workdays: { ...state.workdays, ...action.payload }
      };

    case UPDATE_CAPACITY:
      return {
        ...state,
        capacity: action.payload
      };

    case UPDATE_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };

    default:
      return state;
  }
};

export default function SettingsForm({ settings, onChange }) {
  const [state, dispatch] = useReducer(reducer, settings);

  useEffect(() => {
    onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      <SettingsRow
        title="Workdays"
        subtitle="Mark the days of the week that you typically work."
      >
        {days.map(d => (
          <DayOfWeek
            key={d}
            day={d}
            checked={state.workdays[d]}
            onChange={e =>
              dispatch({
                type: UPDATE_DAY_OF_WEEK,
                payload: { [d]: e.target.checked }
              })
            }
          />
        ))}
      </SettingsRow>

      <SettingsRow
        title="Capacity"
        subtitle="Max amount of hours you work per week."
      >
        <HoursPerWeek
          capacity={state.capacity}
          onChange={e =>
            dispatch({ type: UPDATE_CAPACITY, payload: e.target.value })
          }
        />
      </SettingsRow>

      <SettingsRow
        title="Currency"
        subtitle="Designation that’s displayed throughout the app."
      >
        <Select
          value={state.currency}
          onChange={e =>
            dispatch({ type: UPDATE_CURRENCY, payload: e.target.value })
          }
        >
          {currencies.map(c => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </Select>
      </SettingsRow>
    </>
  );
}
