import React from 'react';
import styles from './SignUpFrame.module.scss';
import Wordmark from '../Wordmark';

export default function SignUpFrame({ title, subtitle, children, footer }) {
  return (
    <div className={styles.wrap}>
      <div className={styles.wordmark}>
        <Wordmark />
      </div>

      <section className={styles.content}>
        <div className={styles.titles}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>

        {children}
      </section>

      <footer className={styles.footer}>{footer}</footer>
    </div>
  );
}
