import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(config);
firebase.firestore().enablePersistence({ synchronizeTabs: true });

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const fieldValues = firebase.firestore.FieldValue;

export { db, auth, functions, firebase, fieldValues };
