import React from 'react';

import Input from '../Input';

import styles from './HoursPerWeek.module.scss';

export default function HoursPerWeek({ capacity, onChange }) {
  function update(e) {
    const val = e.target.value;
    if (val <= 168) onChange(e);
  }

  return (
    <label className={styles.label}>
      <div className={styles.input}>
        <Input type="number" value={capacity} onChange={update} />
      </div>
      hours per week
    </label>
  );
}
