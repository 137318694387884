import React from 'react';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

export default function Checkbox({ checked, onChange, ...rest }) {
  const classes = classNames({
    [styles.target]: true,
    [styles['target--checked']]: checked
  });

  return (
    <div className={styles.wrap}>
      <label className={classes}>
        <input
          type="checkbox"
          className={styles.input}
          onChange={onChange}
          checked={checked}
          {...rest}
        />
      </label>
    </div>
  );
}
