import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import settings from './settings';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    settings
  });

export default createRootReducer;
