import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { authenticate } from '../../redux/actions/auth';

import Button from '../Button/Button';

import styles from './AuthButton.module.scss';

export default function AuthButton({ provider, signUp }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  function handleClick() {
    setLoading(true);
    dispatch(authenticate(provider));
  }

  function icon() {
    switch (provider) {
      case 'Google':
        return (
          <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0,10.5 C0,4.7103 4.7103,0 10.5,0 C12.8383,0 15.0515,0.7523 16.9004,2.1756 L14.4604,5.3452 C13.3172,4.4652 11.9477,4 10.5,4 C6.9159,4 4,6.9159 4,10.5 C4,14.0841 6.9159,17 10.5,17 C13.3867,17 15.8398,15.1087 16.6852,12.5 L10.5,12.5 L10.5,8.5 L21,8.5 L21,10.5 C21,16.2897 16.2897,21 10.5,21 C4.7103,21 0,16.2897 0,10.5 Z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </svg>
        );

      case 'Twitter':
        return (
          <svg width="22" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.91076431,18 C15.212485,18 19.755102,11.0728346 19.755102,5.0757874 C19.755102,4.88090551 19.755102,4.68602362 19.7462985,4.49114173 C20.6266507,3.85334646 21.392557,3.04724409 22,2.13484252 C21.190076,2.4980315 20.3185274,2.73720472 19.4029612,2.8523622 C20.3361345,2.29429134 21.0492197,1.3996063 21.392557,0.336614173 C20.5210084,0.859251969 19.552621,1.23129921 18.522609,1.43503937 C17.695078,0.549212598 16.5242097,0 15.230092,0 C12.7386955,0 10.7138856,2.03740157 10.7138856,4.54429134 C10.7138856,4.89862205 10.7579032,5.24409449 10.8283313,5.58070866 C7.07803121,5.39468504 3.75030012,3.57874016 1.5230092,0.832677165 C1.13565426,1.50590551 0.915566226,2.28543307 0.915566226,3.11811024 C0.915566226,4.69488189 1.71668667,6.08562992 2.92276911,6.90059055 C2.18327331,6.87401575 1.48779512,6.67027559 0.880352141,6.33366142 C0.880352141,6.35137795 0.880352141,6.36909449 0.880352141,6.39566929 C0.880352141,8.59251969 2.43857543,10.4350394 4.49859944,10.851378 C4.12004802,10.9576772 3.72388956,11.0108268 3.31012405,11.0108268 C3.01960784,11.0108268 2.73789516,10.984252 2.46498599,10.9311024 C3.03721489,12.738189 4.70988395,14.0492126 6.68187275,14.0846457 C5.13245298,15.3070866 3.18687475,16.0334646 1.07402961,16.0334646 C0.713085234,16.0334646 0.352140856,16.015748 0,15.9714567 C1.98959584,17.2470472 4.36654662,18 6.91076431,18"
              fill="#FFF"
              fillRule="nonzero"
            />
          </svg>
        );

      case 'Github':
        return (
          <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0000911,0 C4.92570482,0 0,5.0492904 0,11.2783983 C0,16.2615726 3.1518609,20.4892678 7.52253364,21.9806154 C8.07228317,22.0850191 8.27411302,21.7359485 8.27411302,21.4380525 C8.27411302,21.1691056 8.26391223,20.2806472 8.25917615,19.3382126 C5.19894018,20.0204784 4.55319396,18.007486 4.55319396,18.007486 C4.05280894,16.7038408 3.33183192,16.3571982 3.33183192,16.3571982 C2.33379425,15.6571893 3.40706272,15.6715705 3.40706272,15.6715705 C4.51166218,15.7509472 5.09328917,16.8338318 5.09328917,16.8338318 C6.07438626,18.5580799 7.66661975,18.0595944 8.29433244,17.7714104 C8.39306148,17.0426391 8.67813703,16.5447139 8.992722,16.2632536 C6.54945146,15.9780578 3.98103912,15.0109698 3.98103912,10.68933 C3.98103912,9.45796433 4.41074726,8.45184162 5.11441937,7.66199701 C5.000207,7.37773513 4.62368868,6.23078886 5.22098116,4.67724725 C5.22098116,4.67724725 6.14469882,4.37412173 8.24678948,5.83334518 C9.12423929,5.58344873 10.0652619,5.45812697 11.0000911,5.45383129 C11.9349203,5.45812697 12.8766715,5.58344873 13.7557607,5.83334518 C15.8553012,4.37412173 16.7777437,4.67724725 16.7777437,4.67724725 C17.3764935,6.23078886 16.999793,7.37773513 16.8855806,7.66199701 C17.5908922,8.45184162 18.0176858,9.45796433 18.0176858,10.68933 C18.0176858,15.021242 15.4443552,15.9750695 12.9948913,16.2542887 C13.3894432,16.6042931 13.741006,17.2906679 13.741006,18.3429224 C13.741006,19.8518264 13.728255,21.0663829 13.728255,21.4380525 C13.728255,21.7381897 13.9262596,22.0898751 14.4838419,21.9791213 C18.8521466,20.4860927 22,16.2598917 22,11.2783983 C22,5.0492904 17.0750238,0 11.0000911,0"
              fill="#FFFFFE"
              fillRule="evenodd"
            />
          </svg>
        );

      default:
        return '';
    }
  }

  const classes = classNames({
    [styles.wrap]: true,
    [styles[`provider--${provider}`]]: true
  });

  return (
    <div className={classes}>
      <Button loading={loading} fill onClick={handleClick}>
        <div className={styles.icon}>{icon()}</div> Sign {signUp ? 'up' : 'in'}{' '}
        with {provider}
      </Button>
    </div>
  );
}
