import { LOG_IN, LOG_OUT, COMPLETE_ONBOARDING } from '../actionTypes';

const initialState = {
  loading: true,
  authenticated: false,
  user: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOG_IN: {
      const { user } = action.payload;

      return {
        ...state,
        loading: false,
        authenticated: true,
        user
      };
    }

    case LOG_OUT: {
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: null
      };
    }

    case COMPLETE_ONBOARDING: {
      return {
        ...state,
        user: { ...state.user, onboarded: true }
      };
    }

    default:
      return state;
  }
}
