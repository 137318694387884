import React from 'react';
import styles from './Input.module.scss';

export default React.forwardRef(({ ...rest }, ref) => {
  return (
    <div className={styles.wrap}>
      <input ref={ref} className={styles.input} {...rest} />
    </div>
  );
});
