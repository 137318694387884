import { FETCH_SETTINGS, UPDATE_SETTINGS } from '../actionTypes';
import { db } from '../../lib/firebase';

export const fetchSettings = uid => async dispatch => {
  const settings = await db.doc(`settings/${uid}`).get();
  dispatch({ type: FETCH_SETTINGS, payload: { settings: settings.data() } });
};

export const updateSettings = (uid, settings) => async dispatch => {
  await db
    .doc(`/settings/${uid}`)
    .update({ updatedAt: new Date(), ...settings });

  dispatch({ type: UPDATE_SETTINGS, payload: { settings } });
};
