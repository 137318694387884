import React from 'react';

import Checkbox from '../Checkbox';

import styles from './DayOfWeek.module.scss';

export default function DayOfWeek({ day, checked, onChange }) {
  return (
    <div className={styles.wrap}>
      <label>
        <Checkbox checked={checked} onChange={onChange} />
        <div className={styles.label}>{day[0]}</div>
      </label>
    </div>
  );
}
