import React from 'react';
import classNames from 'classnames';

import Loading from '../Loading';
import styles from './LoadingFrame.module.scss';

export default function LoadingFrame({ fullPage }) {
  const classes = classNames({
    [styles.frame]: true,
    [styles.fullPage]: !!fullPage
  });

  return (
    <div className={classes}>
      <Loading />
    </div>
  );
}
